import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import classNames from 'classnames';
import { formatInTimeZone } from 'date-fns-tz';
import MaterialTable from 'material-table';
import React, { useMemo } from 'react';

import { Button } from 'src/common/ui/components';
import { Staff_LabResultStatus as LabResultStatus } from 'src/generated/gql/graphql';
import type { PatientSubmittedTestResult } from 'src/labs/types';
import Colors from 'src/nightingale/Colors';

export const PatientSubmittedResultsTableV2: React.FC<{
  data: PatientSubmittedTestResult[];
  isLoading: boolean;
  onEdit?: (rowData: PatientSubmittedTestResult) => void;
  readOnly?: boolean;
}> = ({ data, isLoading, onEdit, readOnly = false }) => {
  const filteredData = useMemo(() => {
    return data.filter(row => row?.result?.status);
  }, [data]);

  const styles = useStyles();

  const nullableBooleanIcon = (value: boolean | null) => {
    if (value === true) {
      return (
        <span className={classNames(styles.booleanResult, styles.booleanTrueResult)}>
          <CheckIcon /> Yes
        </span>
      );
    }
    if (value === false) {
      return (
        <span className={classNames(styles.booleanResult, styles.booleanFalseResult)}>
          <ClearIcon /> No
        </span>
      );
    }
    return null;
  };

  return (
    <div className={styles.table}>
      <MaterialTable<PatientSubmittedTestResult>
        columns={[
          {
            title: 'Submitted Date',
            field: 'result.createdAt',
            cellStyle,
            defaultSort: 'desc',
            render: row => (
              <Button variant="text" color="primary" onClick={() => onEdit?.(row)}>
                {formatInTimeZone(
                  // TODO: DRY with other date formatting? Make a time.ts for staff?
                  row.result.createdAt,
                  Intl.DateTimeFormat().resolvedOptions().timeZone,
                  'MMM d, yyyy h:mma zzz',
                )}
              </Button>
            ),
          },
          {
            title: 'Results Valid?',
            field: 'result.verified',
            cellStyle,
            render: row => {
              if (row?.result?.status !== LabResultStatus.Resulted) return null;
              return <span>{nullableBooleanIcon(row.result.verified)}</span>;
            },
          },
          {
            title: 'Bup Pos?',
            field: 'result.bupPositive',
            cellStyle,
            render: row => {
              if (row?.result?.status !== LabResultStatus.Resulted) return null;
              return <span>{nullableBooleanIcon(row.result.bupPositive)}</span>;
            },
          },
          {
            title: 'Notes',
            field: 'result.notes',
            cellStyle,
            render: row => <div>{row.result.notes || ''}</div>,
          },
          {
            hidden: readOnly,
            title: 'Status',
            cellStyle,
            render: rowData => {
              if (rowData?.result?.status === LabResultStatus.Resulted) {
                return (
                  <span>
                    <span className={styles.statusCompleteIcon}>
                      <CheckIcon />
                      RESULTED
                    </span>
                  </span>
                );
              } else if (rowData?.result?.status === LabResultStatus.Incomplete) {
                return (
                  <span>
                    <span className={styles.statusIncompleteIcon}>
                      <ClearIcon />
                      INCOMPLETE
                    </span>
                    <IconButton data-testid="edit-icon" onClick={() => onEdit?.(rowData)}>
                      <EditIcon />
                    </IconButton>
                  </span>
                );
              }
              return null;
            },
          },
        ]}
        data={filteredData}
        isLoading={isLoading}
        options={{
          headerStyle: { ...cellStyle, fontWeight: 'bold' },
          ...(readOnly ? { paging: false, sorting: false } : { pageSize: 5 }),
          search: false,
        }}
        title={readOnly ? 'Patient POC Urine Test Results' : 'Patient-submitted Results'}
      />
    </div>
  );
};

// TODO: DRY styles with other tables

const cellStyle = {
  fontFamily: '"Nunito", "Nunito Sans"',
  fontSize: 16,
  fontWeight: 400,
  lineHeight: '145%',
};

const useStyles = makeStyles(theme => ({
  table: {
    '& .MuiToolbar-gutters': {
      padding: theme.spacing(2),
      minHeight: 0,
      width: 'auto',
    },
    '& .MuiTypography-h6': {
      color: Colors.Gray6,
      fontFamily: 'Tenor Sans',
      fontSize: 26,
      fontWeight: 300,
      lineHeight: 1.45,
      margin: 0,
      textTransform: 'capitalize',
    },
    '& .MuiTableCell-root': cellStyle,
    marginBottom: theme.spacing(2),
  },
  booleanResult: {
    borderWidth: 1.5,
    borderRadius: 12,
    borderStyle: 'solid',
    fontWeight: 600,
    padding: '2px 8px',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    '& svg': {
      verticalAlign: 'text-bottom',
      maxHeight: 22,
    },
  },
  booleanTrueResult: {
    backgroundColor: '#e6f2ec',
    borderColor: '#005c2e',
    color: '#005c2e',
  },
  booleanFalseResult: {
    backgroundColor: '#f9e6e8',
    borderColor: '#9e0012',
    color: '#9e0012',
  },
  statusCompleteIcon: {
    borderWidth: 1.5,
    borderRadius: 12,
    borderStyle: 'solid',
    fontWeight: 600,
    padding: '2px 8px',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',

    backgroundColor: '#e6f2ec',
    borderColor: '#005c2e',
    color: '#005c2e',

    '& svg': {
      verticalAlign: 'text-bottom',
      maxHeight: 22,
    },
  },
  statusIncompleteIcon: {
    borderWidth: 1.5,
    borderRadius: 12,
    borderStyle: 'solid',
    fontWeight: 600,
    padding: '2px 8px',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',

    backgroundColor: '#e6f0f9', // Light blue background
    borderColor: '#004b9e', // Dark blue border
    color: '#004b9e', // Dark blue text

    '& svg': {
      verticalAlign: 'text-bottom',
      maxHeight: 22,
    },
  },
}));

export default useStyles;
